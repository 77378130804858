import styled from 'styled-components';
import { dim } from './theme';

const StyledSignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .logoText {
    font-size: 24px;
    font-weight: 600;
    line-height: 31px;

    width: ${dim._scale(180)};
    margin-top: ${dim._40px};
    text-align: center;
  }

  .logoTextPeople {
    font-size: 24px;
    font-weight: 600;
    line-height: 31px;
    color: #2f53d7;
    width: ${dim._scale(180)};
    margin-top: ${dim._40px};
    text-align: center;
  }

  .signInCardContainer {
    padding: ${dim._32px} ${dim._40px} ${dim._40px} ${dim._40px};
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 16px 32px 0 rgba(134, 139, 143, 0.15);
    width: ${dim._scale(304)};
    background-color: ${props => props.theme.color.white};
    margin-top: ${dim._30px};
    border-radius: ${dim._4px};
    .marginTop20 {
      margin-top: 20px;
    }
  }

  .signInCard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .signup_link {
    margin-top: ${dim._30px};
  }

  .googleButton {
    display: flex !important;
    height: ${dim._48px};
    align-items: center;
    width: 100%;
    margin-top: ${dim._24px} !important;
    color: ${props => props.theme.color.blue_50} !important;
    background-color: ${props => props.theme.color.white} !important;
    border-radius: ${dim._4px} !important;
    border: ${props => `solid 1px ${props.theme.color.base_80}`} !important;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 1px 2px 0 rgba(9, 30, 66, 0.15),
      0 0 1px 0 rgba(9, 30, 66, 0.25);
    transition: 0.2s ease-out;

    :hover,
    :focus {
      box-shadow: 0 1px 2px 0 rgba(9, 30, 66, 0.15),
        0 0 1px 0 rgba(9, 30, 66, 0.25), 0 4px 8px 0 rgba(9, 30, 66, 0.15);
    }

    :active {
      box-shadow: 0 1px 2px 0 rgba(9, 30, 66, 0.15),
        0 0 1px 0 rgba(9, 30, 66, 0.25);
    }

    .googleIcon {
      height: ${dim._16px};
      width: ${dim._16px};
      margin-right: ${dim._8px};
    }

    > div {
      padding: ${dim._8px} !important;
    }
  }

  .microsoftButton {
    margin-top: 20px;
  }

  .ssoLoginButton {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .signInDivider {
    display: flex;
    flex: 0.4;
    height: ${dim._1px};
    border-bottom: 1px solid ${props => props.theme.color.base_80};
  }
  .orConatiner {
    display: flex;
    flex: 1;
    width: ${dim._scale(304)};
    flex-direction: row;
    align-items: center;
    margin: ${dim._20px} ${dim._0px};
  }
  .orText {
    display: flex;
    flex: 0.2;
    justify-content: center;
    color: ${props => props.theme.color.base_10};
  }

  .signInHeader {
    color: ${props => props.theme.color.base_10};
    margin-bottom: ${dim._16px};
  }

  .signInDesc {
    text-align: center;
  }

  .eyeIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${dim._12px};
    z-index: 9;
    cursor: pointer;
  }

  .forgotPasswordButton {
    margin-top: ${dim._10px};
    color: ${props => props.theme.color.blue_50};
    cursor: pointer;
  }

  .signUpLink {
    margin-top: ${dim._30px};
  }

  .changeOrgButton {
    margin-top: ${dim._12px};
    background-color: ${props => props.theme.color.white};
  }

  .signUpButton {
    color: ${props => props.theme.color.blue_50};
    cursor: pointer;
    margin-left: ${dim._8px};
  }

  .signUpButtonContainer {
    display: flex;
    flex: 1;
    align-items: center;
    margin-top: ${dim._28px};
  }

  .privacy-policy {
    text-align: center;
    font-size: ${dim._12px};
    padding-top: ${dim._8px};
  }

  .support {
    margin-top: ${dim._16px};
    text-align: center;
    color: ${props => props.theme.color.text_low_emphasis};
  }
`;

export default StyledSignInContainer;

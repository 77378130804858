import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import googleOauth from "@xto10x/oauth2/lib/googleOauth";
import microsoftOauth from "@xto10x/oauth2/lib/microsoftOauth";
import isEmail from "validator/lib/isEmail";
import { SignInCard, SignUpLink } from "../components";
import StyledSignInContainer from "../styles/SignInCard.styled";
import { ErrorMsg, PasswordLength, PeopleCuesSupportEmail } from "../constants";
import ProductLogo from "../components/ProductLogo";
import auth, { initiateAmplifyAuth } from "../utils/auth";
import { cognitoConstants } from "../utils/constants";
import { checkAuthErrorAndDoOperation } from "../utils/utils";

const SignIn = function (props) {
  const { isSignInProgress, setIsSignInProgress } = props;

  const router = useRouter();

  const [passwordError, setPasswordError] = useState({
    error: false,
    errorMessage: ErrorMsg.PASSWORD_LENGTH_ERROR,
  });
  const [emailError, setEmailError] = useState({
    error: false,
    errorMessage: ErrorMsg.INVALID_EMAIL,
  });
  const [password, setPassword] = useState("");
  const [type, setType] = useState(true);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeError, setVerificationCodeError] = useState({
    error: false,
    errorMessage: "Verification is not correct or empty",
  });
  const [isEmailVerificationRequired, setIsEmailVerificationRequired] =
    useState(false);

  const signInWithGoogle = () => {
    props.analytics.logEvent("sign_in", "sign_in_with_google_btn_click");
    props.setIsLoading(true);
    googleOauth
      .getAuthorizationUrl()
      .then((res) => {
        const { url } = res;
        if (!url) {
          props.setIsLoading(false);
          props.setAlertProps({
            type: "error",
            text: "An error occurred, please try again.",
            showAlert: true,
          });
          props.analytics.logException(
            `google_sign_in_failure: fetching google auth url failed`,
            null,
            true,
            res
          );
          return;
        }
        props.setIsLoading(false);
        localStorage.setItem("auth_source", "google");
        localStorage.setItem("login_mode", "SignIn");
        localStorage.setItem(
          "mp_identity",
          props.analytics.getAnalyticsDistinctId()
        );
        window.location.href = url;
      })
      .catch((err) => {
        props.analytics.logException(`google_sign_in_failure: ${err.message}`);
        props.setIsLoading(false);
        props.setAlertProps({
          type: "error",
          text: err.message,
          showAlert: true,
        });
      });
  };

  const signInWithMicrosoft = () => {
    props.analytics.logEvent("sign_in", "sign_in_with_microsoft_btn_click");
    props.setIsLoading(true);
    microsoftOauth
      .getAuthorizationUrl()
      .then((res) => {
        const { url } = res;
        if (!url) {
          props.setIsLoading(false);
          props.setAlertProps({
            type: "error",
            text: "An error occurred, please try again.",
            showAlert: true,
          });
          props.analytics.logException(
            `microsoft_sign_in_failure: fetching microsoft auth url failed`,
            null,
            true,
            res
          );
          return;
        }
        props.setIsLoading(false);
        localStorage.setItem("auth_source", "microsoft");
        localStorage.setItem("login_mode", "SignIn");
        localStorage.setItem(
          "mp_identity",
          props.analytics.getAnalyticsDistinctId()
        );
        window.location.href = url;
      })
      .catch((err) => {
        props.analytics.logException(
          `microsoft_sign_in_failure: ${err.message}`
        );
        props.setIsLoading(false);
        props.setAlertProps({
          type: "error",
          text: err.message,
          showAlert: true,
        });
      });
  };

  const _handleForgotPassword = () => {
    props.analytics.logEvent("sign_in", "forgot_password_btn_click");
    router.push(
      `/forgot-password?redirect_url=${props.redirect_url}&app=${props.app}`
    );
  };

  const _handleEmailChange = (value) => {
    setEmailError({ error: false, errorMessage: ErrorMsg.INVALID_EMAIL });
    props.setEmail(value.toLowerCase().trim());
  };

  const validateEmail = () => {
    if (props.email.length && !isEmail(props.email)) {
      setEmailError({ ...emailError, error: true });
      return false;
    }
    return true;
  };

  const validatePassword = () => {
    if (password.length < PasswordLength) {
      setPasswordError({ ...passwordError, error: true });
      return false;
    }
    setPasswordError({
      error: false,
      errorMessage: ErrorMsg.PASSWORD_LENGTH_ERROR,
    });
    return true;
  };

  const _handlePasswordChange = (value) => {
    setPasswordError({
      error: false,
      errorMessage: ErrorMsg.PASSWORD_LENGTH_ERROR,
    });
    setPassword(value);
  };

  const cognitoSignIn = async () => {
    initiateAmplifyAuth();
    props.setIsLoading(true);
    setIsSignInProgress(true);
    auth
      .signIn(props.email, password)
      .then(({ nextStep }) => {
        props.setIsLoading(false);
        if (nextStep.signInStep !== "DONE") {
          setIsSignInProgress(false);
          if (
            nextStep.signInStep ===
              cognitoConstants.UserNotConfirmedException ||
            nextStep.signInStep === cognitoConstants.CONFIRM_SIGN_UP
          ) {
            auth.resendSignUpCode(props.email.toLowerCase());
            props.setAlertProps({
              type: "success",
              text: "Enter verification code sent in an email to complete signup.",
              showAlert: true,
            });
            setIsEmailVerificationRequired(true);
          }
        }
      })
      .catch((err) => {
        props.setIsLoading(false);
        setIsSignInProgress(false);
        props.analytics.logException(
          `sign_in_error: ${err.message}`,
          props.email
        );
        checkAuthErrorAndDoOperation(err, props.setAlertProps, null, {
          email: props.email,
          redirectUrl: props.redirect_url,
        });
      });
  };

  const _handleVerificationOfCode = () => {
    initiateAmplifyAuth();
    try {
      auth
        .confirmSignUp(
          props.email?.toLowerCase(),
          password,
          verificationCode,
          true
        )
        .catch((e) => {
          if (e.name === "NotAuthorizedException") {
            auth.signIn(props.email?.toLowerCase(), password);
            return;
          }
          props.setAlertProps({
            type: "error",
            text: e.message,
            showAlert: true,
          });
          props.analytics.logException(
            `confirm_sign_up_code_verification_error: ${e.message}`,
            props.email?.toLowerCase()
          );
        });
    } catch (e) {
      props.setAlertProps({ type: "error", text: e.message, showAlert: true });
      props.analytics.logException(
        `confirm_sign_up_code_verification_error: ${e.message}`,
        props.email
      );
    }
  };

  const handleVerificationCodeChange = (val) => {
    if (val.trim().length === 0) {
      setVerificationCodeError({
        error: true,
        errorMessage: "Verification is not correct or empty",
      });
    } else {
      setVerificationCodeError({
        error: false,
        errorMessage: "Verification is not correct or empty",
      });
    }
    setVerificationCode(val.trim());
  };

  return (
    <StyledSignInContainer>
      <ProductLogo />
      <SignUpLink
        setEmail={props.setEmail}
        app={props.app}
        redirect_url={props.redirect_url}
      />
      <SignInCard
        email={props.email}
        handleEmail={_handleEmailChange}
        password={password}
        handlePassword={_handlePasswordChange}
        type={type}
        handleType={setType}
        onForgotPasswordClick={_handleForgotPassword}
        signIn={cognitoSignIn}
        validateEmail={validateEmail}
        validatePassword={validatePassword}
        emailError={emailError}
        passwordError={passwordError}
        disabled={
          !(isEmail(props.email) && password.length) || isSignInProgress
        }
        verifyCode={_handleVerificationOfCode}
        verificationCode={verificationCode}
        verificationCodeError={verificationCodeError}
        handleVerificationCodeChange={handleVerificationCodeChange}
        isEmailVerificationRequired={isEmailVerificationRequired}
        microsoftAuthEnabled={props.microsoftAuthEnabled}
        signInWithGoogle={signInWithGoogle}
        signInWithMicrosoft={signInWithMicrosoft}
        app={props.app}
        redirect_url={props.redirect_url}
        orgConfig={props.orgConfig}
        isInitialized={props.isInitialized}
        ssoSignIn={props.ssoSignIn}
      />
      <div className="support header12">
        Trouble signing in? Reach out to us at{" "}
        <div>{PeopleCuesSupportEmail}</div>
      </div>
    </StyledSignInContainer>
  );
};

export default SignIn;
